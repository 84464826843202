<template>
  <v-app
    :theme="mainStore.theme"
    class="default-layout"
  >
    <v-app-bar
      color="primary"
      prominent
    >
      <v-app-bar-nav-icon
        aria-label="Open navigation drawer"
        color="secondary"
        @click.stop="drawer = !drawer"
      />
      <v-app-bar-title>
        <h1 class="text-h4">jason's website - {{ routeName }}</h1>
      </v-app-bar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      role="navigation"
    >
      <v-list aria-label="Pages to navigate to">
        <v-list-item
          v-for="page in pagesList"
          :key="page.title"
          :to="page.to"
          role="option"
        >
          <v-list-item-title :class="{ 'ml-4': page.isChild }">{{ page.title }}</v-list-item-title>
          <v-list-item-subtitle
            v-if="page.subtitle"
            :class="{ 'ml-4': page.isChild }"
          >
            {{ page.subtitle }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main role="main">
      <slot />
    </v-main>
    <default-footer />
    <error-snackbar :snackbar="mainStore.snackbar.snackbar" />
  </v-app>
</template>
<script setup lang="ts">
import { ref, useRoute } from '#imports';

import DefaultFooter from '~/components/DefaultFooter.vue';
import { useStore } from '~/store/mainStore.js';
import { usePostStore } from '~/store/postStore.js';
import { NUM_POSTS_TO_DISPLAY } from '~/utils/constants';

const mainStore = useStore();
const postStore = usePostStore();

const props = defineProps({
  error: {
    type: Object,
    default: null,
    required: false
  }
})

const drawer = ref(false);
const pages = ref([
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'Games',
    to: '/games',
  },
  {
    title: 'Blog',
    to: '/blog',
  },
  {
    title: 'Interesting links',
    to: '/links',
  },
  {
    title: 'Cat and Mouse game',
    to: '/catandmouse',
  },
  {
    title: '2048 Clone',
    to: '/2048',
  },
  {
    title: 'Code Editor',
    to: '/codeeditor',
  },
  {
    title: 'Pong',
    to: '/pong',
  },
]);

// I want to maintain a nested structure for data structure, but the navigation drawer doesn't support that
// so, we flatten it
const pagesList = computed(() => {
  const pagesList = [];
  for (const page of pages.value) {
    pagesList.push(page);
    if (page.children) {
      for (const child of page.children)
        pagesList.push({
          ...child,
          isChild: true,
        });
    }
  }
  return pagesList;
});

onMounted(async () => {
  await postStore.init();
  const newestPosts = postStore.posts
    .filter((post: BlogPost) => post.status === 'published')
    .sort((a: BlogPost, b: BlogPost) => b.createdAt.toMillis() - a.createdAt.toMillis())
    .slice(0, NUM_POSTS_TO_DISPLAY);
  pages.value.find(page => page.title === 'Blog').children = newestPosts.map((post: BlogPost) => ({
    title: post.title,
    subtitle: postTimestamps(post, true),
    to: `/blog/${post.title.replace(/\s/g, '+').toLowerCase()}`,
  }));
});

const route = useRoute();
const routeName = computed(() => props.error?.statusCode || route.name);
</script>

<style lang="scss">
.default-layout {

  // stylelint-disable-next-line selector-class-pattern
  &.v-theme--dark {
    a:not(.v-list-item) {
      color: rgb(var(--v-theme-secondary-lighten-3));

      &:visited {
        color: rgb(var(--v-theme-secondary-lighten-2));
      }
    }
  }
}
</style>
